.upload-file-wrap

  p
    +margin(right 0.63vw)
    text-decoration: underline
    line-height: 1.88vw
    float: left

    +tablet
      +margin(right 1.56vw)
      line-height: 4.69vw

    +mobile
      +margin(right 1.11vw)
      +size(31.94vw)
      line-height: 10vw
      overflow: hidden
      text-overflow: ellipsis

  input
    display: none

  label
    +btn(null, null, 0.31vw)
    width: 4.58vw !important
    height: 1.88vw
    background-color: #e6e9f1
    line-height: 1.88vw

    +tablet
      width: 11.46vw !important
      height: 4.69vw
      border-radius: 0.78vw
      line-height: 4.69vw

    +mobile
      width: 24.44vw !important
      height: 10vw
      border-radius: 1.67vw
      line-height: 10vw