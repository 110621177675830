#press-page

  section
    +margin(top 2.08vw)
    +padding(0 16.67vw)
    position: relative

    +tablet
      +margin(top 5.21vw)
      +padding(0 3.13vw)

    +mobile
      +margin(top 22.22vw)
      +padding(0 5.56vw)

  .press-table-wrap
    +flex-box(space-between)
    +size(100%)
    flex-direction: column

    table
      +size(100%)

      thead
        background-color: $background-color-article

        tr
          +border(0.1vw solid $border-color-base, bottom)

          +tablet
            +border(0.26vw solid $border-color-base, bottom)

          +mobile
            +border(0.28vw solid $border-color-base, bottom)

          th
            +padding(1.04vw)
            font-weight: bold

            +tablet
              +padding(3.13vw 0)

            +mobile
              +padding(2.78vw 0)

      tbody
        text-align: center

        tr
          +border(0.05vw solid $border-color-base, top bottom)

          +tablet
            +border(0.13vw solid $border-color-base, top bottom)

          +mobile
            +border(0.28vw solid $border-color-base, top bottom)

          td
            +padding(1.04vw)

            &:nth-child(2)
              +size(42.92vw)

            &:nth-child(3)
              +size(13.23vw)

            +tablet
              +padding(3.13vw 0)

              &:nth-child(2)
                +size(70.31vw)

              &:nth-child(3)
                +size(15.63vw)

            +mobile
              +padding(2.78vw 0)

              &:nth-child(2)
                +size(44.44vw)

              &:nth-child(3)
                +size(28.61vw)

    .article-pagination-wrap
      +margin(4.17vw 0 6.25vw)
      +size(100%)
      +flex-box(center)

      +tablet
        +margin(10.42vw 0 15.63vw)

      +mobile
        +margin(11.11vw 0 16.67vw)
