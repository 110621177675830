.image-list-wrap
  +padding(top 2.08vw)

  +tablet
    +padding(top 2.6vw)

  +mobile
    +padding(top 0)

  .image-container
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 3.13vw 2.08vw

    +tablet
      grid-template-columns: repeat(2, 1fr)
      gap: 7.81vw 5.21vw

    +mobile
      grid-template-columns: repeat(2, 1fr)
      gap: 7.78vw 5.56vw

    .image-card
      text-align: center
      line-height: normal

      img
        +size(100%)
        aspect-ratio: 1 / 1

      h4
        +margin(top 1.67vw)
        +size(20.83vw, 1.56vw)
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

        +tablet
          +margin(top 4.17vw)
          +size(44.27vw, 3.91vw)

        +mobile
          +margin(top 5.56vw)
          +size(41.67vw, 4.72vw)

      p
        +margin(top 0.42vw)
        font-size: 0.83vw
        color: $gray-700

        +tablet
          +margin(top 1.04vw)
          font-size: 2.08vw

        +mobile
          +margin(top 1.11vw)
          font-size: 3.89vw

  .pagination-wrap
    +margin(4.17vw 0 6.25vw)
    +size(100%)
    +flex-box(center)

    +tablet
      +margin(10.42vw 0 15.63vw)

    +mobile
      +margin(11.11vw 0 16.67vw)
