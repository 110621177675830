@mixin size($width: null, $height: null) {
  $width_attr: width, max, min;
  $height_attr: height, max, min;

  @if type-of($width) == 'list' {
    @if type-of(nth($width, 1)) == 'string' {
      @each $attr in $width_attr {
        $i: index($width, $attr);
        @if $i {
          @if $attr == width {
            width: nth($width, $i + 1);
          } @else {
            #{$attr}-width: nth($width, $i + 1);
          }
        }
      }
    } @else {
      @for $i from 1 through length($width) {
        @if $i == 1 {
          width: nth($width, 1);
        } @else {
          #{nth($width_attr, $i)}-width: nth($width, $i);
        }
      }
    }
  } @else {
    width: $width;
  }

  @if type-of($height) == 'list' {
    @if type-of(nth($height, 1)) == 'string' {
      @each $attr in $height_attr {
        $i: index($height, $attr);
        @if $i {
          @if $attr == height {
            height: nth($height, $i + 1);
          } @else {
            #{$attr}-height: nth($height, $i + 1);
          }
        }
      }
    } @else {
      @for $i from 1 through length($height) {
        @if $i == 1 {
          height: nth($height, 1);
        } @else {
          #{nth($height_attr, $i)}-height: nth($height, $i);
        }
      }
    }
  } @else {
    height: $height;
  }
}

@mixin flex-box($jfc: null, $agn: null, $gap-width: null, $gap-vertical: null) {
  display: flex;
  @if $jfc != null {
    justify-content: $jfc;
  } @else {
    justify-content: flex-start;
  }

  @if $agn != null {
    align-items: $agn;
  } @else {
    align-items: flex-start;
  }

  @if ($gap-vertical != null or $gap-width != null) {
    gap: $gap-vertical $gap-width
  }
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin margin($args: 0) {
  $null-check: false;

  @if type-of($args) == 'list' {
    @if type-of(nth($args, 1)) == 'string' {
      @each $attr in top, right, bottom, left {
        $i: index($args, $attr);
        @if $i {
          margin-#{$attr}: nth($args, $i + 1);
        }
      }
    } @else {
      $args-length: length($args);
      @for $i from 1 through $args-length {
        @if nth($args, $i) == null {
          $null-check: true;
        }
      }
      @if $null-check {
        @if $args-length == 2 {
          margin: nth($args, 1) nth($args, 2);
        } @else
        if $args-length == 3 {
          margin: nth($args, 1) nth($args, 2) nth($args, 3);
        } @else {
          margin: nth($args, 1) nth($args, 2) nth($args, 3) nth($args, 4);
        }
      } @else {
        @if $args-length == 2 {
          $m-t-b: nth($args, 1);
          $m-r-l: nth($args, 2);
          margin: $m-t-b $m-r-l;
        } @else
        if $args-length == 3 {
          $m-t: nth($args, 1);
          $m-r-l: nth($args, 2);
          $m-b: nth($args, 3);
          margin: $m-t $m-r-l $m-b;
        } @else {
          $m-t: nth($args, 1);
          $m-r: nth($args, 2);
          $m-b: nth($args, 3);
          $m-l: nth($args, 4);
          margin: $m-t $m-r $m-b $m-l;
        }
      }
    }
  } @else {
    margin: $args;
  }
}

@mixin padding($args: 0) {
  $null-check: false;

  @if type-of($args) == 'list' {
    @if type-of(nth($args, 1)) == 'string' {
      @each $attr in top, right, bottom, left {
        $i: index($args, $attr);
        @if $i {
          padding-#{$attr}: nth($args, $i + 1);
        }
      }
    } @else {
      $args-length: length($args);
      @for $i from 1 through $args-length {
        @if nth($args, $i) == null {
          $null-check: true;
        }
      }
      @if $null-check {
        @if $args-length == 2 {
          padding: nth($args, 1) nth($args, 2);
        } @else
        if $args-length == 3 {
          padding: nth($args, 1) nth($args, 2) nth($args, 3);
        } @else {
          padding: nth($args, 1) nth($args, 2) nth($args, 3) nth($args, 4);
        }
      } @else {
        @if $args-length == 2 {
          $m-t-b: nth($args, 1);
          $m-r-l: nth($args, 2);
          padding: $m-t-b $m-r-l;
        } @else
        if $args-length == 3 {
          $m-t: nth($args, 1);
          $m-r-l: nth($args, 2);
          $m-b: nth($args, 3);
          padding: $m-t $m-r-l $m-b;
        } @else {
          $m-t: nth($args, 1);
          $m-r: nth($args, 2);
          $m-b: nth($args, 3);
          $m-l: nth($args, 4);
          padding: $m-t $m-r $m-b $m-l;
        }
      }
    }
  } @else {
    padding: $args;
  }
}

@mixin border($value: null, $prop: null){
  @if $value != null {
    @if $prop == null {
      border: $value;
    } @else {
      @each $attr in top, right, bottom, left {
        $i: index($prop, $attr);
        @if $i {
          border-#{$attr}: $value;
        }
      }
    }
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin background($image: null, $repeat: null, $position: null null, $color: null) {
  @if $color != null and $image != null and $repeat != null and nth($position, 1) != null and nth($position, 2) != null {
    background: $color url($image) $repeat $position;
  } @else {
    @if $color != null {
      background-color: $color;
    }
    @if $image != null {
      background-image: url($image);
    }
    @if $repeat != null {
      background-repeat: $repeat;
    }
    @if $position != null {
      background-position: $position;
    }
  }
}

@mixin box-sizing($type: content-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}

@mixin box-shadow($type) {
  -webkit-box-shadow: $type;
  -moz-box-shadow: $type;
  box-shadow: $type;
}

@mixin ellipse($truncation-boundary: null, $line: null) {
  @if $truncation-boundary != null {
    max-width: $truncation-boundary;
  }
  @if $line != null {
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
    }
  } @else {
    display: block;
    white-space: nowrap;
  }
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin prefix($prop, $value, $vendors: webkit moz ms o) {
  @if $vendors {
    @each $vendor in $vendors {
      -#{$vendor}-#{$prop}: #{$value}
    }
  }
  #{$prop}: #{$value};
}

@mixin font-face($name, $file, $weight) {
  @font-face {
    font-family: "#{$name}";
    font-weight: #{$weight};
    src: url("@/assets/fonts/#{$file}.otf");
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@mixin mobile {
  @media (max-width: #{$screen-tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$screen-tablet}) and (max-width: #{$screen-size-base - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$screen-size-base}) {
    @content;
  }
}