@import "styles/common/helpers/_mixins.scss"
@import "styles/common/helpers/_variables.sass"

.pagination
  +flex-box()

  li

    a
      +size(2.08vw, 2.08vw)
      display: inline-block
      font-size: 0.83vw
      text-align: center
      line-height: 2.08vw
      border-radius: 3.13vw

      +tablet
        +size(5.21vw, 5.21vw)
        font-size: 2.08vw
        line-height: 5.21vw
        border-radius: 7.81vw

      +mobile
        +size(10vw, 10vw)
        font-size: 3.89vw
        line-height: 10vw
        border-radius: 16.67vw

      &:hover
        background-color: $page-color

  .disabled
    color: $disabled

    a
      cursor: not-allowed

      &:hover
        background: none
    
  .active a
    color: $white
    background-color: $page-sub-color