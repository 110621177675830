.quill {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ql-toolbar.ql-snow {
  border-color: #cbd5e1;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.ql-container.ql-snow {
  border-color: #cbd5e1;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  min-height: calc(100% - 40px);
}

.ql-container.ql-snow .ql-video {
  width: 100%;
  aspect-ratio: 16 / 9;
}