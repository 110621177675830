%input-default
    +box-sizing(border-box)
    +padding(12px 24px)
    outline: none
    font-size: 16px
    background-color: $background-color-input

    &::placeholder
        color: $gray-900
        font-size: 16px
        line-height: noraml

    &:focus
        outline: none

%input-base
    +border(1px solid $border-color-base)

%ghost
    +border(0)

@mixin input($type: null, $width: null, $height: null, $border: null, $radius: null)
    @extend %input-default

    @if $type == base
        @extend %input-base
    @else if $type == ghost
        @extend %ghost

    @if $width !=null
        @include size($width, $height)

    @if $border != null
        @include border($border)

    @if $radius != null
        @include border-radius($radius)
    @else
        @include border-radius(0)
