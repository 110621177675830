#qna-create
  +padding(0 16.67vw)

  +tablet
    +padding(0 3.13vw)

  +mobile
    +padding(0 5.56vw)

  form
    +flex-box(null, null, 1.04vw)
    +margin(4.17vw auto 7.29vw)
    +size(38.02vw)
    +box-sizing(border-box)
    flex-direction: column

    +tablet
      +size(100%)
      +margin(10.42vw auto 15.63vw)
      gap: 2.6vw

    +mobile
      +size(100%)
      +margin(5.56vw auto 16.67vw)
      gap: 5.56vw

    section
      +size(100%)
      +flex-box(null, null)
      flex-direction: column

      .input-wrap
        +size(100%)
        +flex-box(null, center)

        label
          +size(6.93vw)
          display: inline-block

          +tablet
            +size(17.32vw)

          +mobile
            +size(31.11vw)

        .required::before
          +margin(right 0.21vw)
          content: "*"
          color: $required

          +tablet
            +margin(right 0.52vw)

          +mobile
            +margin(right 1.11vw)

        input
          +input(base)
          +size(15.63vw)

          +tablet
            +size(32.29vw)

          +mobile
            +size(100%)
            flex-grow: 1
            flex-basis: 0

        textarea
          +border(0.05vw solid $border-color-base)
          +padding(0.83vw 1.25vw)
          flex-grow: 1
          resize: none
          outline: none

          +tablet
            +border(0.13vw solid $border-color-base)
            +padding(2.08vw 3.13vw)

          +mobile
            +border(0.28vw solid $border-color-base)
            +padding(4.44vw 5.56vw)
            flex-basis: 0

        input[id="title"]
          flex-grow: 1

      .error-wrap
        +padding(top 1.04vw left 6.93vw)

        +tablet
          +padding(left 17.32vw)

        +mobile
          +padding(top 1.11vw left 30.56vw)

        p
          color: $error

    .button-wrap
      align-items: center
      +margin(top 3.13vw)
      
      +tablet
        +margin(top 10.42vw)

      +mobile
        +margin(top 16.67vw)

      button
        +btn(sub-page, null, 2.08vw)
        +size(15.36vw, 3.39vw)
        font-size: 1.04vw

        +tablet
          +size(38.41vw, 8.46vw)
          border-radius: 5.21vw
          font-size: 2.6vw

        +mobile
          +size(72.78vw, 12.22vw)
          border-radius: 11.11vw
          font-size: 3.89vw
