@import "styles/common/helpers/_variables.sass"
@import "styles/common/helpers/_mixins.scss"
@import "styles/client/uix/_button.sass"
@import "styles/client/uix/_input.sass"
@import "styles/common/uix/search-form.sass"
@import "styles/common/uix/pagination.sass"
@import "styles/common/uix/article-list.sass"
@import "styles/common/uix/image-list.sass"
@import "styles/common/uix/upload-file.sass"

@import "styles/client/common/header.sass"
@import "styles/client/common/aside.sass"
@import "styles/client/common/footer.sass"
@import "styles/client/home"
@import "styles/client/reservation"
@import "styles/client/intro"
@import "styles/client/gallery"
@import "styles/client/event"
@import "styles/client/support"
@import "styles/client/policy"
@import "styles/client/404"

body

  #root
    font-family: SUIT-regular, sans-serif

    .desktop

      +tablet
        display: none !important

      +mobile
        display: none !important

    .tablet

      +desktop
        display: none !important

      +mobile
        display: none !important

    .mobile

      +desktop
        display: none !important

      +tablet
        display: none !important

    main

      h1
        font-size: 2.5vw
        font-weight: bold

        +tablet
          font-size: 6.25vw

        +mobile
          font-size: 10vw

      h2
        font-size: 2.08vw
        font-weight: bold

        +tablet
          font-size: 5.21vw

        +mobile
          font-size: 7.78vw

      h3
        font-size: 1.67vw
        font-weight: bold

        +tablet
          font-size: 4.17vw

        +mobile
          font-size: 5.56vw

      h4
        font-size: 1.25vw
        font-weight: bold

        +tablet
          font-size: 3.13vw

        +mobile
          font-size: 3.89vw

      h5
        font-size: 1.04vw
        font-weight: bold

        +tablet
          font-size: 2.6vw

        +mobile
          font-size: 3.89vw

      p
        font-size: 0.94vw

        +tablet
          font-size: 2.34vw

        +mobile
          font-size: 3.89vw

    .main-pages
      min-height: calc($full-vh - $footer-height)

      header
        +size(100%, 25vw)
        background-color: $page-color
        position: relative

        +tablet
          height: 51.56vw

        +mobile
          height: 55.56vw

        picture
          +position(absolute, 0, 0)
          +size(100%, 100%)

          img
            +size(100%, 100%)
            object-fit: cover

        ul
          +flex-box()
          position: absolute
          left: 16.67vw
          bottom: 1.46vw
          font-size: 0.73vw
          line-height: normal
          color: $white

          +tablet
            left: 3.13vw
            bottom: 3.13vw
            font-size: 1.82vw

          +mobile
            left: 5.56vw
            bottom: -6.67vw
            font-size: 3.33vw
            color: $gray-200
            transform: translateY(50%)

          li
            opacity: 0.7

            &::before
              content: ">"
              +margin(0 0.21vw)

              +tablet
                +margin(0 0.52vw)

              +mobile
                +margin(0 1.11vw)

            &:first-child::before
              content: none

            &:has(.active)
              opacity: 1

      .title
        +margin(top 4.17vw)
        +padding(0 16.67vw)
        font-size: 2.71vw !important

        +tablet
          +margin(top 10.42vw)
          +padding(0 3.13vw)
          font-size: 6.77vw !important

        +mobile
          +margin(top 17.5vw)
          +padding(0 5.56vw)
          font-size: 7.78vw !important

.not-found
  +padding(9.38vw 0 10.42vw)
  font-size: 1.67vw
  text-align: center

  +tablet
    +padding(18.23vw 0 23.44vw)
    font-size: 4.17vw

  +mobile
    +padding(18.23vw 0 23.44vw)
    font-size: 5.56vw