#product-page

  section
    +padding(0 16.67vw)

    +tablet
      +padding(0 3.13vw)

    +mobile
      +padding(0 5.56vw)

      h2
        font-size: 5.56vw !important

  .course-section
    +margin(top 2.08vw)

    +tablet
      +margin(top 5.21vw)

    +mobile
      +margin(top 5.56vw)

    table
      +margin(top 3.13vw)

      +tablet
        +margin(top 5.21vw)

      +mobile
        +margin(top 6.67vw)

      tr
        +border(2.08vw solid transparent, bottom)
        font-size: 1.25vw
        font-weight: bold
        line-height: 2.6vw

        +tablet
          +border(5.21vw solid transparent, bottom)
          font-size: 3.13vw
          line-height: 6.51vw

        +mobile
          +flex-box()
          +border(6.67vw solid transparent, bottom)
          flex-direction: column
          font-size: 4.44vw
          line-height: normal

        th
          +size(7.45vw, 2.6vw)
          background-color: $page-color
          display: inline-block
          text-align: center
          color: $white

          +tablet
            +size(18.62vw, 6.51vw)

          +mobile
            +size(24.17vw, 7.78vw)
            line-height: 7.78vw

        td
          +padding(left 2.08vw)

          +tablet
            +padding(left 4.17vw)

          +mobile
            +padding(top 4.44vw left 0)
            word-break: normal

            &:nth-child(3)
              +padding(top 2.22vw)

            span:not(:first-child)::before
              +margin(0 2.22vw)
              content: "-"
              color: $gray-900

    p
      font-size: 0.83vw
      line-height: 1.63

      +tablet
        font-size: 2.08vw

      +mobile
        font-size: 3.89vw

  .fee-section
    +margin(top 5.21vw)

    +tablet
      +margin(top 13.02vw)

    +mobile
      +margin(top 16.67vw)

    .table-wrap
      +size(100%)

      +mobile
        overflow-x: scroll

      table
        +size(100%)
        +margin(top 2.08vw)
        text-align: center
        font-size: 0.94vw
        line-height: normal

        +tablet
          +margin(top 5.21vw)
          font-size: 2.34vw

        +mobile
          +size(fit-content)
          +margin(top 6.67vw)
          font-size: 3.89vw

        thead
          +border(0.05vw solid $border-color-table)

          +tablet
            +border(0.13vw solid $border-color-table)

          +mobile
            +border(0.28vw solid $border-color-table)

          th
            +padding(1.25vw)
            background-color: $background-color-table
            font-weight: 600

            +tablet
              +size(18.23vw)
              +padding(3.13vw 0)

            +mobile
              +padding(12px)
              min-width: 80px
              white-space: nowrap

            &:not(:last-child)
              +border(0.05vw solid $border-color-table, right)

              +tablet
                +border(0.13vw solid $border-color-table)

              +mobile
                +border(0.28vw solid $border-color-table)

          .time-1

            +tablet
              +size(18.23vw)

          .time-2

            +tablet
              min-width: 13.02vw

          .extra

            +tablet
              min-width: 24.74vw

        tbody

          th
            +border(0.05vw solid $border-color-table, bottom right)
            vertical-align: middle
            word-break: keep-all

            +tablet
              +border(0.13vw solid $border-color-table, bottom right)

            +mobile
              +padding(10.5px)
              +border(0.28vw solid $border-color-table, bottom right)

          td
            +size(10.68vw)
            +padding(1.25vw)
            +border(0.05vw solid $border-color-table)
            vertical-align: middle
            white-space: nowrap

            +tablet
              +size(14.32vw)
              +padding(3.13vw 0)
              +border(0.13vw solid $border-color-table)

            +mobile
              +padding(10px)
              +border(0.28vw solid $border-color-table)

            &:last-child
              +border(none, right)

          .tip
            +size(100%)
            +padding(1.25vw)
            +border(0)
            +border(0.05vw solid $border-color-table, bottom)
            font-size: 0.94vw
            text-align: center
            line-height: normal
            
            +tablet
              +border(0.13vw solid $border-color-table, bottom)
              +padding(3.13vw)
              gap: 5.86vw
              font-size: 2.34vw

            +mobile
              +border(0.28vw solid $border-color-table, bottom)
              +padding(2.78vw)
              font-size: 3.89vw

            span
              +margin(0 2.34vw)

              +tablet
                +margin(0 5.86vw)

              +mobile
                +margin(0 4.81vw)

    .ps
      +margin(top 1.04vw)
      font-size: 0.83vw
      line-height: 1.63

      +tablet
        +margin(top 5.21vw)
        font-size: 2.08vw

      +mobile
        +margin(top 6.67vw)
        font-size: 3.89vw

  .schedule-section
    +margin(top 5.21vw)

    +tablet
      +margin(top 13.02vw)

    +mobile
      +margin(top 16.67vw)

    table
      +margin(top 3.13vw)

      +tablet
        +margin(top 5.21vw)

      +mobile
        +margin(top 6.67vw)

      tr
        +border(2.08vw solid transparent, bottom)
        font-size: 1.25vw
        font-weight: bold
        line-height: 2.6vw

        +tablet
          +border(5.21vw solid transparent, bottom)
          font-size: 3.13vw
          line-height: 6.51vw

        +mobile
          +flex-box()
          +border(6.67vw solid transparent, bottom)
          flex-direction: column
          font-size: 4.44vw
          line-height: normal

        th
          +size(7.45vw, 2.6vw)
          background-color: $page-color
          display: inline-block
          text-align: center
          color: $white

          +tablet
            +size(18.62vw, 6.51vw)

          +mobile
            +size(24.17vw, 7.78vw)
            line-height: 7.78vw

        td
          +padding(left 2.08vw)

          +tablet
            +padding(left 5.21vw)

          +mobile
            +padding(top 4.44vw left 0)
            word-break: normal

            &:nth-child(3)
              +padding(top 2.22vw)

          span

            &:after
              +margin(0 1.25vw)
              content: "/"
              color: #999

              +tablet
                +margin(0 2.08vw)
                word-break: break-all

              +mobile
                +margin(0 2.22vw)
                word-break: break-all

            &:last-child::after
              content: none

  .link-section
    +margin(4.17vw 0 6.25vw)
    +flex-box(center, center, 2.08vw)

    +tablet
      +margin(7.81vw 0 15.63vw)
      gap: 5.21vw

    +mobile
      +margin(10vw 0 22.22vw)
      flex-direction: column
      gap: 4.44vw

    a
      +btn(sub-page, null, 2.08vw)
      +size(15.36vw)
      +padding(1.04vw)
      line-height: normal
      font-size: 1.04vw

      +tablet
        +size(38.41vw)
        +padding(2.6vw)
        border-radius: 5.21vw
        font-size: 2.6vw

      +mobile
        +size(72.78vw)
        +padding(3.89vw)
        border-radius: 11.11vw
        font-size: 3.89vw
        list-style: 1.14
