.search-wrap
  position: absolute
  top: -2.08vw
  right: 16.67vw
  transform: translateY(-100%)

  +tablet
    top: -5.73vw
    right: 3.13vw

  +mobile
    top: -5.56vw
    right: 11.11vw

  form
    +input(base, 12.19vw, 2.29vw, null, 7.81vw)
    +flex-box(null, center)
    +padding(0.63vw 1.15vw 0.63vw 1.25vw)
    font-size: 0.83vw

    +tablet
      +input(base, 30.47vw, 5.73vw, null, 7.81vw)
      +padding(1.56vw 2.86vw 1.56vw 3.13vw)
      font-size: 2.08vw

    +mobile
      +size(77.78vw, 11.11vw)
      +padding(2.22vw 5vw 2.22vw 5.56vw)
      border-width: 0.28vw
      border-radius: 16.67vw
      font-size: 3.89vw

    input
      +size(100%)
      outline: none

    button
      +size(1.25vw, 1.25vw)

      +tablet
        +size(3.13vw, 3.13vw)

      +mobile
        +size(6.67vw, 6.67vw)

      img
        +size(100%, 100%)
