$page-color: #535e85
$page-sub-color: #00194e

// color
$white: #ffffff
$black: #222222
$blue: #3d59b1
$sky-blue: #f1f6fd
$brown: #6c6761
$disabled: #c8c8c8

$gray-200: #222222
$gray-300: #333333
$gray-400: #444444
$gray-600: #666666
$gray-500: #555555
$gray-700: #777777
$gray-800: #888888
$gray-900: #999999
$gray-8a: #8a8a8a
$gray-a: #aaaaaa
$gray-c: #cccccc
$gray-ed: #ededed
$gray-e6: #e6e6e6
$gray-e8: #e8e8e8
$gray-f0: #f0f0f0
$gray-f4: #f4f4f4
$gray-f6: #f6f6f6
$gray-f7: #f7f7f7
$gray-f8: #f8f8f8
$gray-f9: #f9f9f9
$gray-fa: #fafafa
$gray-fb: #fbfbfb
$gray-10: #101010

//border
$border-color-base: #e7e9f1
$border-color-table: #e6e9f1
$border-color-e7: #0f0e0e
$border-color-e6: #e6e6e6
$border-color-e5: #e5e5e5
$border-color-e3: #e3e3e3
$border-color-a8: #a8a8a8
$border-color-78: #78b0e9

//background
$background-color-base: rgba(0, 0, 0, 0.1)
$background-color-table: #cfd4e3
$background-color-article: #fafafd
$background-color-footer: #000715
$background-color-disabled: #c8c8c8
$background-color-cancel: #777777
$background-color-comment: #efefef
$background-color-input: #ffffff

//error
$error: #ff0000
$required: #ff1200

// font
$font-color-base: #222222
$font-color-footer: #ececec

$line-for-base: 1.2

// size
$full-vh: calc(var(--vh, 1vh) * 100)

$screen-mobile: 360px
$screen-tablet: 768px
$screen-size-base: 1024px

$header-height: 5.21vw
$footer-height: 17.45vw

$header-height-tablet: 13.02vw
$footer-height-tablet: 60.29vw

$header-height-mobile: 20vw
$footer-height-mobile: 148.06vw
