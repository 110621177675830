#policy
  +padding(0 16.67vw 6.25vw)

  +tablet
    +padding(0 3.13vw 16.15vw)

  +mobile
    +padding(0 5.56vw 16.67vw)

  section
    +margin(top 2.08vw)

    +tablet
      +margin(top 7.81vw)

    +mobile
      +margin(top 11.11vw)

    p
      +padding(top 1.04vw)
      line-height: 1.63

      +tablet
        +padding(top 2.6vw)

      +mobile
        +padding(top 3.33vw)
        
      strong
        font-weight: bold
