@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  #admin * {
    @apply transition-all duration-300 font-[SUIT-regular];
  }

  #admin main {
    @apply bg-slate-100 box-border min-w-[calc(1024px-theme("spacing.admin-aside"))] min-h-[calc(100vh-theme("spacing.admin-header"))] mt-admin-header p-12 !important;
  }

  #admin article {
    @apply transition-all duration-300 bg-white rounded-lg;
  }

  #admin input {
    @apply h-10 px-4 text-lg border rounded-lg outline-none border-slate-300;
  }

  #admin .required::after {
    content: "*";
    color: red;
    font-weight: 700;
  }
}

@layer components {
  #admin article h1 {
    @apply p-6 text-2xl font-bold border-b-2 border-slate-300 !important;
  }

  #admin p {
    @apply text-base !important;
  }

  #admin table {
    @apply w-full text-center;
  }

  #admin thead tr {
    @apply text-white bg-theme-light;
  }

  #admin tbody tr {
    @apply border-t border-b border-black;
  }

  #admin th {
    @apply px-3 py-4;
  }

  #admin td {
    @apply p-3;
  }

  #admin table button {
    @apply w-20 py-2 rounded-md;
  }

  #admin table a {
    @apply w-20 py-2 rounded-md;
  }

  #admin article .admin-form {
    @apply flex flex-col gap-5 py-10;
  }

  #admin article .input-wrap {
    @apply flex items-center gap-10 px-10;
  }

  #admin article .input-wrap label {
    @apply flex items-center justify-center w-20 gap-1;
  }

  #admin article .input-wrap input {
    @apply py-2 text-base;
  }

  #admin .toggle-button {
    @apply cursor-pointer relative h-7 rounded-full appearance-none w-14 border-2 border-gray-500 m-1 before:top-0 before:absolute before:content-[''] before:left-0 before:w-6 before:h-6 before:rounded-full before:scale-75 before:bg-gray-500 before:transition-[left] before:duration-300 checked:before:bg-white checked:before:left-7 checked:bg-theme checked:border-theme disabled:border-gray-300 disabled:before:bg-gray-300 disabled:opacity-75 disabled:cursor-not-allowed focus-visible:ring-2 focus-visible:ring-theme focus-visible:ring-offset-2 hover:ring-2 hover:ring-gray-300 hover:ring-offset-2 hover:ring-offset-gray-300;
  }

  #admin .not-found {
    @apply border-b-0;
  }

  #admin .not-found td {
    @apply py-52 text-2xl;
  }
}
