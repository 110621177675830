#clubhouse-page

  .prepare-section
    +size(100%)
    +padding(9.38vw 0 10.42vw)
    +flex-box(center, center, 2.08vw)
    flex-direction: column
    line-height: normal

    +tablet
      +padding(18.23vw 0 23.44vw)
      gap: 5.21vw

    +mobile
      +padding(18.23vw 0 23.44vw)
      gap: 5.21vw

    p
      text-align: center
      line-height: 1.56

  section
    +margin(top 2.08vw)
    +padding(0 16.67vw)

    +tablet
      +margin(top 5.21vw)
      +padding(0 3.13vw)

    +mobile
      +margin(top 5.56vw)
      +padding(0 5.56vw)
    
    .carousel-container
      +flex-box(center, center)

      .carousel-wrap
        +margin(top 2.6vw)
        +size(100%, 32.34vw)

        +tablet
          +margin(top 7.81vw)
          +size(100%, 40.36vw)

        +mobile
          +margin(top 11.11vw)
          +size(100%, 38.61vw)

        .swiper-slide
          +size(100%)

        img
          +size(57.5vw, 32.34vw)
          +margin(auto)
          border-radius: 0.365vw

          +tablet
            +size(71.88vw, 40.36vw)
            border-radius: 0.91vw

          +mobile
            +size(68.89vw, 38.61vw)
            border-radius: 1.94vw
        
        .swiper-button-prev, .swiper-button-next
          +size(2.5vw, 2.5vw)
          top: 50%
          color: $page-sub-color

          +tablet
            +size(6.25vw, 6.25vw)

          +mobile
            +size(6.67vw, 6.67vw)

          &:after
            font-size: 2.03vw

            +tablet
              font-size: 5.08vw

            +mobile
              font-size: 5.42vw

        .swiper-button-prev
          left: 0

        .swiper-button-next
          right: 0

        .swiper-button-disabled
          opacity: 0.4

  .facility-section
    +margin(top 5.21vw)
    +padding(5.21vw 16.67vw 6.25vw)
    background-color: #e6e9f1

    +tablet
      +margin(top 13.02vw)
      +padding(10.42vw 3.13vw 13.02vw)

    +mobile
      +margin(top 16.67vw)
      +padding(11.11vw 5.56vw 16.67vw)
