#qna-list

  section
    +margin(top 2.08vw)
    +padding(0 16.67vw)
    position: relative

    +tablet
      +margin(top 5.21vw)
      +padding(0 3.13vw)

    +mobile
      +margin(top 22.22vw)
      +padding(0 5.56vw)

    .button-wrap
      position: absolute
      top: -4.27vw
      right: 16.67vw

      +tablet
        top: -11.2vw
        right: 3.13vw

      +mobile
        top: -30.56vw
        right: 5.56vw

      a
        +btn(page, null, 3.13vw)
        +size(5.21vw)
        +padding(0.63vw)
        font-size: 0.83vw

        +tablet
          +size(13.02vw)
          +padding(1.56vw)
          border-radius: 7.81vw
          font-size: 2.08vw

        +mobile
          +size(27.78vw)
          +padding(2.22vw)
          border-radius: 8.33vw
          font-size: 3.89vw

  .qna-table-wrap
    +flex-box(space-between)
    +size(100%)
    flex-direction: column

    table
      +size(100%)

      thead
        background-color: $background-color-article

        tr
          +border(0.1vw solid $border-color-base, bottom)

          +tablet
            +border(0.26vw solid $border-color-base, bottom)

          +mobile
            +border(0.28vw solid $border-color-base, bottom)

          th
            +padding(1.04vw)
            font-weight: bold

            +tablet
              +padding(3.13vw 0)

            +mobile
              +padding(2.78vw 0)

      tbody
        text-align: center

        tr
          +border(0.05vw solid $border-color-base, top bottom)

          +tablet
            +border(0.13vw solid $border-color-base, top bottom)

          +mobile
            +border(0.28vw solid $border-color-base, top bottom)

          td
            +padding(1.04vw)

            &:first-child
              +size(6.25vw)

            &:nth-child(2)
              +size(32.29vw)

            &:nth-child(3), &:nth-child(4)
              +size(10.42vw)

            +tablet
              +padding(3.13vw 0)

              &:first-child
                +size(7.81vw)

              &:nth-child(2)
                +size(39.06vw)

              &:nth-child(3), &:nth-child(4)
                +size(15.63vw)

            +mobile
              +padding(2.78vw 0)

              &:first-child
                +size(57px)

              &:nth-child(2)
                +size(160px)

              &:nth-child(3)
                +size(69px)

              &:nth-child(4)
                +size(34px)

    .article-pagination-wrap
      +margin(4.17vw 0 6.25vw)
      +size(100%)
      +flex-box(center)

      +tablet
        +margin(10.42vw 0 15.63vw)

      +mobile
        +margin(11.11vw 0 16.67vw)
