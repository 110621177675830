#not-found
  +padding(top $header-height)
  +size(100%, calc(100vh - $footer-height))
  +flex-box(center, center)
  background-color: $disabled
  color: $white
  text-align: center
  font-size: 2.5vw
  font-weight: bold

  +tablet
    font-size: 6.25vw

  +mobile
    font-size: 10vw
