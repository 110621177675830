#mobile-menu
  +size(100vw, 100vh)
  position: fixed
  background-color: rgba(0, 0, 0, 0.9)
  z-index: 999
  color: $white

  .button-wrap
    +flex-box(flex-end)
    +padding(6.67vw 5.56vw 5.56vw)

    button
      +size(6.67vw, 6.67vw)

      img
        +size(100%, 100%)

  nav

    ul

      li
        +padding(5.56vw 7.78vw)
        +flex-box(space-between)
        flex-direction: column
        font-size: 5vw
        font-weight: 600
        line-height: normal

        .menu
          +size(100%)
          +flex-box(space-between)

          img
            transition: transform 0.3s ease

          .active img
            transform: rotate(180deg)

        .sub-menu
          +flex-box(flex-end, null, 6.67vw)
          +box-sizing(content-box)
          flex-direction: column
          transition: all 0.3s ease
          overflow: hidden

          li
            +padding(0)
            opacity: 0.7
            font-size: 3.89vw
