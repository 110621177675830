#notice-detail
  +padding(0 16.67vw)
  
  +tablet
    +padding(0 3.13vw)

  +mobile
    +padding(0 5.56vw)

  .content-section
    +border(0.05vw solid $border-color-table, top bottom)
    +margin(top 1.25vw)

    +tablet
      +border(0.13vw solid $border-color-table, top bottom)
      +margin(top 3.13vw)

    +mobile
      +border(0.28vw solid $border-color-table, top bottom)
      +margin(top 6.67vw)

    .title-wrap
      +border(0.05vw solid $border-color-base, bottom)
      +padding(1.25vw 1.04vw)
      line-height: normal

      +tablet
        +border(0.13vw solid $border-color-base, bottom)
        +padding(3.13vw 2.6vw)

      +mobile
        +border(0.28vw solid $border-color-base, bottom)
        +padding(4.44vw)

      p
        +margin(top 0.63vw)
        color: $gray-700

        +tablet
          +margin(top 1.56vw)

        +mobile
          +margin(top 1.11vw)

    .content-wrap
      +margin(2.08vw auto 5.21vw)
      +size(46.88vw)

      +tablet
        +margin(5.21vw auto 5.21vw)
        +size(78.13vw)

      +mobile
        +margin(6.67vw auto 16.67vw)
        +size(77.78vw)
      
      .ql-video
        width: 100%
        aspect-ratio: 16 / 9

    .file-wrap
      +border(0.05vw solid $border-color-base, top)
      +padding(1.25vw 1.04vw)
      +flex-box(null, null, 0.63vw)
      font-size: 0.83vw
      font-weight: bold
      line-height: 1.63

      +tablet
        +border(0.13vw solid $border-color-base, top)
        +padding(3.13vw 2.6vw)
        gap: 1.56vw
        font-size: 2.08vw

      +mobile
        +border(0.28vw solid $border-color-base, top)
        +padding(4.86vw 4.44vw)
        gap: 3.33vw
        font-size: 3.89vw

      a
        +flex-box(null, null, 0.21vw)
        font-weight: normal
        text-decoration: underline

        +tablet
          gap: 0.52vw

        +mobile
          gap: 1.11vw

        img
          +size(1.25vw, 1.25vw)
          +margin(0)

          +tablet
            +size(3.13vw, 3.13vw)

          +mobile
            +size(5.56vw, 5.56vw)
            
  .button-section
    +flex-box(flex-end)
    +margin(1.25vw auto 6.25vw)

    +tablet
      +margin(3.13vw auto 15.63vw)

    +mobile
      +margin(4.44vw auto 22.22vw)

    a
      +btn(null, null, 0.31vw)
      +size(3.13vw, 1.88vw)
      background-color: #e6e9f1
      font-size: 0.83vw
      line-height: 1.88vw
      font-weight: 500

      +tablet
        +size(7.81vw, 4.69vw)
        border-radius: 0.78vw
        font-size: 2.08vw
        line-height: 4.69vw

      +mobile
        +size(15.83vw, 9.17vw)
        border-radius: 1.67vw
        font-size: 3.89vw
        line-height: 9.17vw