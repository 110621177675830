// 버튼 default
%btn-default
    +box-sizing(border-box)
    display: block
    // background: none
    font-size: 16px
    font-weight: bold
    text-align: center
    text-decoration: none
    outline: none
    cursor: pointer
    
    &:disabled
        background-color: $background-color-disabled
        color: $white
        cursor: not-allowed

%btn-page
    background-color: $page-color
    color: $white

%btn-sub-page
    background-color: $page-sub-color
    color: $white

%btn-cancel
    background-color: $background-color-cancel
    color: $white

%btn-edit
    background-color: $gray-8a
    color: $white

%btn-comment
    background-color: $background-color-comment

%btn-white
    background-color: $white
    display: inline-block
    font-weight: bold

@mixin btn($type: null, $border: null, $radius: null)
    @extend %btn-default

    @if $type == page
        @extend %btn-page
    @else if $type == sub-page
        @extend %btn-sub-page
    @else if $type == cancel
        @extend %btn-cancel
    @else if $type == edit
        @extend %btn-edit
    @else if $type == comment
        @extend %btn-comment
    @else if $type == white
        @extend %btn-white
    @else
        background-color: transparent

    @if $border != null
        @include border($border)
    @else
        @include border(0)

    @if $radius != null
        @include border-radius($radius)
    @else
        @include border-radius(0)
