#gallery-list
  +padding(0 16.67vw)
  position: relative

  +tablet
    +margin(top 5.21vw)
    +padding(0 3.13vw)

  +mobile
    +margin(top 22.22vw)
    +padding(0 5.56vw)