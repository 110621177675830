#reservation-page

  iframe
    +padding(100px 0)
    +size(1280px, 1500px)
    +box-sizing()
    margin: auto
    overflow: hidden

    +tablet
      +size(720px, 1950px)
    
    +mobile
      +padding(30px 0)
      +size(320px, 1800px)
