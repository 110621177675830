.footer-wrap
  +size(100%)
  +padding(3.13vw 16.67vw)
  +flex-box(center)
  flex-direction: column
  height: $footer-height
  background-color: $background-color-footer
  color: rgba(255, 255, 255, 0.8)

  +tablet
    height: $footer-height-tablet
    +padding(8.85vw 7.81vw)

  +mobile
    height: $footer-height-mobile
    +padding(15.56vw 11.11vw)

  .image-wrap
    +size(9.49vw, 2.08vw)
    +margin(right 6.19vw)

    +tablet
      +size(23.72vw, 5.21vw)
      +margin(right 0)

    +mobile
      +size(45.56vw, 10vw)

  .content-wrap
    +margin(top 2.08vw)
    +size(100%)
    +flex-box(space-between, null)

    +tablet
      +margin(top 5.21vw)
      flex-direction: column
      gap: 6.25vw

    +mobile
      +margin(top 11.11vw)
      flex-direction: column
      gap: 11.11vw

    .policy-wrap

      .link-wrap
        +flex-box(null, null, 1.04vw)
        font-weight: bold
        line-height: normal
        color: $white
        font-size: 0.83vw

        +tablet
          gap: 2.6vw
          font-size: 2.08vw

        +mobile
          gap: 5.56vw
          font-size: 3.89vw

      p
        font-size: 0.73vw
        line-height: normal

        +tablet
          font-size: 1.82vw

        +mobile
          font-size: 3.33vw
          line-height: 1.67

      .address-wrap
        +margin(top 1.67vw bottom 0.94vw)
        font-weight: 500

        +tablet
          +margin(top 4.17vw bottom 2.34vw)

        +mobile
          +margin(top 6.67vw bottom 4.44vw)

        p
          line-height: 1.71

          +mobile
            font-size: 3.89vw

        p span:not(:last-child):after
          +margin(0 0.63vw)
          content: "|"
          color: rgba(255, 255, 255, 0.3)

          +tablet
            +margin(0 1.95vw)

      .copyright-wrap
        opacity: 0.5
        font-weight: bold

    .sns-wrap
      +flex-box(null, flex-end, 0.83vw)
      height: 100%

      +tablet
        gap: 2.6vw

      +mobile
        gap: 5.56vw

      a
        +flex-box(center, center)
        +size(2.08vw, 2.08vw)
        background-color: rgba(111, 126, 151, 0.3)
        border-radius: 0.52vw

        +tablet
          +size(5.21vw, 5.21vw)
          border-radius: 1.3vw
          
        +mobile
          +size(11.11vw, 11.11vw)
          border-radius: 2.78vw

        img
          +size(1.25vw, 1.25vw)

          +tablet
            +size(3.13vw, 3.13vw)

          +mobile
            +size(6.67vw, 6.67vw)
