.header-wrap
  +padding(0 3.13vw)
  +flex-box(space-between, center)
  +size(100%, $header-height)
  position: absolute
  z-index: 999

  +tablet
    +padding(4.43vw 3.13vw)
    height: $header-height-tablet

  +mobile
    +padding(6.67vw 5.56vw)
    height: $header-height-mobile

  .home-wrap

    a
      +size(7.59vw, 1.67vw)
      display: inline-block

      +tablet
        +size(18.98vw, 4.17vw)

      +mobile
        +size(30.36vw, 6.67vw)

      img
        +size(100%, 100%)

  nav
    +mobile
      display: none

    ol
      +flex-box(null, null, 4.17vw)

      +tablet
        gap: 5.21vw

      li
        position: relative

        a
          color: $white
          text-decoration: none
          font-size: 0.83vw
          font-weight: 600
          opacity: 0.7

          +tablet
            font-size: 2.08vw !important

          &:hover
            text-decoration: underline
            +tablet
              font-weight: bold
              text-decoration: none

        .active
          opacity: 1
          font-weight: bold

        .dropdown-menu
          +flex-box(null, null, 0)
          +padding(top 1.46vw)
          width: max-content
          flex-direction: column
          position: absolute
          z-index: 99
          top: 100%
          left: 50%
          transform: translateX(-50%)

          +tablet
            +padding(top 3.65vw)

          li
            +size(100%)
            +box-sizing(border-box)
            cursor: pointer

            a
              +size(100%, 100%)
              +padding(0.63vw)
              display: inline-block
              font-size: 0.83vw
              text-align: center

              +tablet
                +padding(1.56vw)

            &:hover a
              text-decoration: underline

              +tablet
                text-decoration: none

  .menu-btn
    +size(6.67vw, 6.67vw)

    img
      +size(6.67vw, 6.67vw)
