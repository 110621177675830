.main-home

  h1
    line-height: normal

  .home-header

    .swiper-wrap
      +size(100%, 50vw)
      +margin(auto)
      background-color: $page-color

      +tablet
        +size(100%, 111.98vw)

      +mobile
        +size(100%, 155.56vw)

      img
        +size(100%, 50vw)
        object-fit: cover

        +tablet
          +size(100%, 111.98vw)

        +mobile
          +size(100%, 155.56vw)

      .swiper-button-prev, .swiper-button-next
        +size(1.25vw, 1.25vw)
        top: 43.65vw
        color: $white

        +tablet
          +size(3.13vw, 3.13vw)
          top: 101.3vw

        &:after
          font-size: 1.04vw

          +tablet
            font-size: 2.6vw

      .swiper-button-prev
        left: 16.67vw

        +tablet
          left: 2.6vw

      .swiper-button-next
        left: 19.17vw

        +tablet
          left: 8.85vw

      .swiper-button-disabled
        opacity: 0.4

      .swiper-pagination
        +flex-box(center, center)
        +padding(0 16.67vw)
        bottom: 5.21vw

        +tablet
          +padding(0 3.13vw)
          bottom: 7.81vw

        +mobile
          +padding(0 5.56vw)
          bottom: 12.22vw

        .swiper-pagination-bullet
          +margin(0)
          +size(100%, 0.1vw)
          flex-grow: 1
          border-radius: 0
          background: $white
          opacity: 0.2

          +tablet
            height: 0.26vw

          +mobile
            height: 0.56vw

        .swiper-pagination-bullet-active
          opacity: 1

    .intro-wrap
      top: 15vw
      left: 16.67vw
      position: absolute
      color: $white
      z-index: 99

      +tablet
        top: 33.85vw
        left: 50%
        transform: translateX(-50%)

      +mobile
        top: 31.11vw
        left: 50%
        transform: translateX(-50%)

      h1
        white-space: nowrap

        +tablet
          text-align: center

        +mobile
          text-align: center

      p
        +margin(top 1.67vw)
        line-height: 1.56

        +tablet
          +margin(top 4.17vw)
          text-align: center

        +mobile
          +margin(top 6.67vw)
          text-align: center

      a
        +margin(top 3.13vw)
        +btn($white)
        +size(11.25vw, 3.13vw)
        +padding(0)
        color: $black
        border-radius: 2.08vw
        line-height: 3.13vw
        font-size: 1.04vw

        +tablet
          +margin(15.63vw auto 0)
          +size(28.13vw, 7.81vw)
          border-radius: 5.21vw
          font-size: 2.6vw
          line-height: 7.81vw
          display: block

        +mobile
          +margin(14.44vw auto 0)
          +size(63.89vw, 14.44vw)
          border-radius: 16.67vw
          font-size: 4.44vw
          line-height: 14.44vw
          display: block

  article
    +padding(top 4.17vw)
    background-color: $white

    +tablet
      +padding(top 13.02vw)

    +mobile
      +padding(top 22.22vw)

    .section-carousel
      +flex-box(flex-start, center, 3.13vw)
      +size(100%)

      +tablet
        flex-direction: column-reverse
        gap: 10.42vw

      +mobile
        flex-direction: column-reverse
        gap: 16.67vw

      .carousel-wrap
        +size(60.42vw, 22.92vw)
        background-color: $page-color

        +tablet
          +size(100%, 39.06vw)

        +mobile
          +size(100%, 55.56vw)

        img
          +size(60.42vw, 22.92vw)

          +tablet
            +size(100%, 39.06vw)

          +mobile
            +size(100%, 55.56vw)

        .swiper-button-prev, .swiper-button-next
          +size(1.25vw, 1.25vw)
          top: 21.67vw
          color: $white

          +tablet
            display: none

          &:after
            font-size: 1.04vw

        .swiper-button-prev
          left: 55.42vw

        .swiper-button-next
          right: 1.25vw

        .swiper-button-disabled
          opacity: 0.4

      .intro-wrap
        +size(19.06vw)

        +tablet
          +size(100%)
          text-align: center

        +mobile
          +size(100%)
          +padding(0 5.56vw)
          text-align: center

        p
          +margin(top 1.04vw)
          line-height: 1.44
          white-space: nowrap

          +tablet
            +margin(top 2.6vw)

          +mobile
            +margin(top 3.33vw)

        a
          +margin(top 3.13vw)
          +btn(sub-page, null, 2.08vw)
          +size(11.25vw, 3.13vw)
          line-height: 3.13vw
          color: white
          font-size: 1.04vw
          font-weight: bold

          +tablet
            +margin(7.81vw auto 0)
            +size(28.13vw, 7.81vw)
            display: block
            border-radius: 5.21vw
            line-height: 7.81vw
            font-size: 2.6vw

          +mobile
            +margin(11.11vw auto 0)
            +size(63.89vw, 12.78vw)
            display: block
            border-radius: 11.11vw
            line-height: 12.78vw
            font-size: 3.89vw

    .section-product
      +flex-box(center, null, 6.2vw)
      +margin(top 5.21vw)
      text-align: center

      +tablet
        +margin(top 13.02vw)
        align-items: center
        flex-direction: column
        gap: 10.42vw

      +mobile
        +margin(top 22.22vw)
        align-items: center
        flex-direction: column
        gap: 16.67vw

      .text-wrap
        +margin(top 3.13vw)
        text-align: start

        +tablet
          +margin(0)
          text-align: center

        +mobile
          +margin(0)
          text-align: center

        p
          +margin(top 1.04vw)
          line-height: 1.44

          +tablet
            +margin(top 2.6vw)

          +mobile
            +margin(top 3.33vw)

        a
          +margin(top 3.13vw)
          +btn(sub-page, null, 2.08vw)
          +size(11.25vw, 3.13vw)
          line-height: 3.13vw
          color: white
          font-size: 1.04vw
          font-weight: bold

          +tablet
            +margin(7.81vw auto 0)
            +size(28.13vw, 7.81vw)
            display: block
            border-radius: 5.21vw
            line-height: 7.81vw
            font-size: 2.6vw

          +mobile
            +margin(11.11vw auto 0)
            +size(63.89vw, 12.78vw)
            display: block
            border-radius: 11.11vw
            line-height: 12.78vw
            font-size: 3.89vw

      .img-wrap
        +flex-box(center, center, 2.08vw)

        +tablet
          gap: 2.6vw

        +mobile
          gap: 4.44vw

        div
          position: relative
          background-color: $gray-f7

          img
            +size(20.83vw, 26.04vw)

            +tablet
              +size(45.57vw, 56.9vw)

            +mobile
              +size(42.22vw, 52.78vw)

          h5
            position: absolute
            right: 1.46vw
            bottom: 1.46vw
            color: $white
            font-weight: bold
            line-height: 1.3

            +tablet
              right: 3.65vw
              bottom: 3.65vw

            +mobile
              right: 50%
              bottom: 3.33vw
              transform: translateX(50%)

    .section-reservation
      +margin(5.21vw 16.67vw 0)
      +padding(3.54vw 3.13vw)
      position: relative
      color: $white
      line-height: normal

      +tablet
        +margin(10.42vw 3.13vw 0)
        +padding(8.85vw 7.81vw)

      +mobile
        +margin(16.67vw 5.56vw 0)
        +padding(7.78vw 6.67vw)

        h1
          font-size: 6.67vw !important

        h4
          font-weight: 500 !important

      picture
        +position(absolute, 0, 0)
        +size(100%, 100%)

        img
          +size(100%, 100%)
          border-radius: 0.36vw
          object-fit: cover

          +tablet
            border-radius: 0.91vw

          +mobile
            border-radius: 1.94vw

      .link-wrap
        +flex-box(null, center, 0.63vw)
        position: relative
        z-index: 99

        +tablet
          gap: 1.56vw

        +mobile
          gap: 2.22vw

        a
          +flex-box(center, center)
          +size(2.08vw, 2.08vw)
          background-color: rgba(0, 0, 0, 0.4)
          border-radius: 2.08vw

          +tablet
            +size(5.21vw, 5.21vw)
            border-radius: 5.21vw

          +mobile
            +size(6.67vw, 6.67vw)
            border-radius: 6.67vw

          img
            +size(1.67vw, 1.67vw)

            +tablet
              +size(4.17vw, 4.17vw)

            +mobile
              +size(5.33vw, 5.33vw)

      h4
        +margin(top 0.83vw)
        position: relative
        z-index: 99

        +tablet
          +margin(top 2.08vw)

        +mobile
          +margin(top 2.22vw)

    .section-link
      +margin(top 4.17vw)
      +padding(0 16.67vw)
      +flex-box(space-between)

      +tablet
        +margin(top 22.22vw)
        +padding(0 3.13vw)
        flex-direction: column
        gap: 5.21vw

      +mobile
        +margin(top 16.67vw)
        +padding(0 5.56vw)
        flex-direction: column
        gap: 5.56vw

      .link-container
        +border(0.05vw solid $border-color-base)
        +padding(1.88vw 1.67vw)
        +size(32.29vw)
        +flex-box(space-between)
        border-radius: 0.36vw

        +tablet
          +padding(4.69vw 4.17vw)
          +size(100%)
          border-radius: 0.91vw

        +mobile
          +padding(6.67vw 5.56vw)
          +size(100%)
          border-radius: 1.94vw

        a
          font-size: 1.04vw
          font-weight: bold
          line-height: 1.3

          +tablet
            font-size: 2.6vw

          +mobile
            font-size: 3.89vw

        p
          +margin(top 0.83vw)
          line-height: 1.44

          +tablet
            +margin(top 2.08vw)

          +mobile
            +margin(top 2.22vw)

        img
          +size(5.21vw, 5.21vw)

          +tablet
            +size(13.02vw, 13.02vw)

          +mobile
            +size(16.67vw, 16.67vw)

    .section-support
      +margin(top 5.21vw)
      +padding(4.17vw 16.67vw)
      +flex-box(space-between)
      background-color: #f0f2f6

      +tablet
        +margin(top 10.42vw)
        +padding(10.42vw 3.13vw)
        flex-direction: column

      +mobile
        +margin(top 16.67vw)
        +padding(11.11vw 5.56vw)
        flex-direction: column

      .support-wrap
        +size(30.73vw)

        +tablet
          +size(100%)

        +mobile
          +size(100%)

        div
          +flex-box(space-between, center)

          a
            +btn()
            color: $gray-700
            font-size: 0.83vw

            +tablet
              font-size: 2.08vw

            +mobile
              font-size: 3.89vw

        ul
          +margin(top 1.46vw)
          font-size: 0.94vw
          line-height: 2.22

          +tablet
            +margin(top 3.65vw)
            font-size: 2.34vw

          +mobile
            +margin(top 4.44vw)
            font-size: 3.89vw
            line-height: 2.29

          a
            +flex-box(space-between)

            p
              +size(24.58vw, 2.08vw)
              overflow: hidden
              text-overflow: ellipsis
              white-space: nowrap

              +tablet
                +size(61.46vw, 5.21vw)

              +mobile
                +size(63.33vw, 8.89vw)

              &:hover
                text-decoration: underline

            span
              color: $gray-700

      .map-wrap

        +tablet
          +size(100%)
          +margin(top 10.42vw)

        +mobile
          +size(100%)
          +margin(top 14.44vw)

        .header
          +flex-box(space-between, center)

          a
            +btn()
            color: $gray-700
            font-size: 0.83vw

            +tablet
              font-size: 2.08vw

            +mobile
              font-size: 3.89vw

        .content-wrap
          +flex-box(null, center, 1.93vw)
          +margin(top 1.46vw)

          +tablet
            +margin(top 3.65vw)
            gap: 5.21vw

          +mobile
            +margin(top 4.44vw)
            flex-direction: column
            gap: 4.44vw

          .map-img
            +size(11.46vw, 6.25vw)

            +tablet
              +size(41.67vw, 15.63vw)

            +mobile
              +size(100%, 44.44vw)

            img
              +size(100%, 100%)
              object-fit: cover

          .text-wrap
            line-height: 1.44
            font-weight: 500

            +mobile
              +size(100%)
              p
                font-size: 5vw !important

            b
              font-weight: bold

            .phone-wrap
              +margin(top 0.63vw)
              line-height: normal

              +tablet
                +margin(top 1.56vw)

              +mobile
                +margin(top 3.33vw)
