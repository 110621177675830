#yacht-page

  section
    +padding(0 16.67vw)

    +tablet
      +padding(0)

    +mobile
      +padding(0)

  .intro-section
    +margin(top 4.17vw)

    +tablet
      +margin(top 5.21vw)

    +mobile
      +margin(top 11.11vw)

    .intro-wrap
      +margin(top 4.17vw)
      +size(100%, 29.27vw)
      +flex-box(null, center)
      position: relative

      +tablet
        +margin(top 5.21vw)
        height: 90.76vw
        justify-content: center
        align-items: flex-start

      +mobile
        +margin(top 11.11vw)
        height: 211.39vw
        justify-content: center
        align-items: flex-start

      .text-wrap
        +size(37.5vw, 23.02vw)
        +padding(2.08vw 1.67vw)
        background-color: $white

        +tablet
          +size(93.75vw, 57.55vw)
          +padding(5.21vw 4.17vw)

        +mobile
          +size(88.89vw, 169.72vw)
          +padding(5.56vw 4.44vw)

        h5
          color: $page-sub-color
          line-height: 1.4

          +mobile
            font-size: 5vw
            line-height: 1.33

        p
          +margin(top 1.04vw)
          font-size: 0.83vw
          line-height: 1.63

          +tablet
            +margin(top 2.6vw)
            font-size: 2.08vw

          +mobile
            +margin(top 4.44vw)
            font-size: 3.89vw
            line-height: 1.64

      img
        +size(49.48vw, 29.27vw)
        position: absolute
        top: 0
        right: 0
        z-index: -1

        +tablet
          +size(100%, 59.11vw)
          top: auto
          bottom: 0

        +mobile
          +size(100%, 58.33vw)
          top: auto
          bottom: 0
  
  .video-section
    +flex-box(space-between, center)
    +margin(top 4.17vw)

    +tablet
      +margin(top 10.42vw)
      flex-direction: column
      gap: 5.21vw

    +mobile
      +margin(top 11.11vw)
      flex-direction: column
      gap: 5.56vw

    video
      +size(32.29vw, 18.13vw)
      background-color: $black

      +tablet
        +size(100%, 56.12vw)

      +mobile
        +size(100%, 56.11vw)

  .swiper-section
    +margin(top 5.21vw)

    +tablet
      +margin(top 13.02vw)
      +padding(0 3.13vw)

    +mobile
      +margin(top 16.67vw)
      +padding(0 5.56vw)

    .carousel-wrap
      +size(100%)
      +margin(top 3.13vw)
      +padding(0 2.08vw)
      position: relative

      +tablet
        +margin(top 7.81vw)
        +padding(0 3.13vw)
        
      +mobile
        +margin(top 11.11vw)
        +padding(0 10vw)

      button
        position: absolute
        top: 50%
        transform: translateY(-50%)

        &:disabled
          opacity: 0.3

        img
          +size(2.5vw, 2.5vw)

          +tablet
            +size(6.25vw, 6.25vw)

          +mobile
            +size(6.67vw, 6.67vw)
      
      .carousel-prev
        left: 0
      .carousel-next
        right: 0

      img
        +size(18.75vw, 14.38vw)
        +margin(auto)
        border-radius: 0.37vw
        object-fit: cover

        +tablet
          +size(37.76vw, 29.04vw)
          border-radius: 0.91vw

        +mobile
          +size(68.89vw, 52.78vw)
          border-radius: 1.94vw

  .address-section
    +margin(5.21vw 0 6.25vw)

    +tablet
      +margin(14.71vw 0 15.63vw)
      +padding(0 3.13vw)

    +mobile
      +margin(16.67vw 0 48.89vw)
      +padding(0 5.56vw)

    #map
      +margin(top 2.08vw)
      +size(100%, 20.89vw)
      position: relative

      +tablet
        +margin(top 5.21vw)
        height: 39.06vw

      +mobile
        +margin(top 7.78vw)
        height: 88.89vw

      picture
        +position(absolute, 0, 0)
        +size(100%, 100%)

        img
          +size(100%, 100%)
  
      .address-wrap
        position: absolute
        top: 1.88vw
        left: 1.88vw

        +tablet
          top: 4.69vw
          left: 4.69vw

        +mobile
          top: auto
          bottom: -5.56vw
          left: 0
          transform: translateY(100%)

        p
          line-height: 1.56
          font-weight: 500

          strong
            font-weight: bold

          +mobile
            line-height: 1.64

        .phone
          +margin(top 0.63vw)
          line-height: normal

          +tablet
            +margin(top 1.56vw)

          +mobile
            +margin(top 2.22vw)