#qna-detail

  .login-section

    form
      +margin(7.29vw auto 9.38vw)
      +size(29.69vw)
      +flex-box(center, center)
      flex-direction: column
      text-align: center

      +tablet
        +margin(18.23vw auto 23.44vw)
        +size(74.22vw)

      +mobile
        +margin(11.11vw auto 16.67vw)
        +size(88.89vw)

      h3
        white-space: nowrap

      input
        +input(base, 100%, 2.71vw)
        +margin(top 1.46vw)
        +padding(0.83vw 1.25vw)

        +tablet
          +margin(top 3.65vw)
          +padding(2.08vw 3.13vw)
          height: 6.77vw

        +mobile
          +margin(top 6.67vw)
          +padding(4.44vw 5.56vw)
          height: 13.61vw

      p
        +margin(top 0.63vw)
        +size(100%)
        color: $error
        text-align: start

        +tablet
          +margin(top 1.56vw)

        +mobile
          +margin(top 3.33vw)

      button
        +margin(top 3.13vw)
        +btn(sub-page, null, 2.08vw)
        +size(15.36vw, 3.39vw)
        font-size: 1.04vw

        +tablet
          +margin(top 7.81vw)
          +size(38.41vw, 8.46vw)
          border-radius: 5.21vw
          font-size: 2.6vw

        +mobile
          +margin(top 16.67vw)
          +size(72.78vw, 12.22vw)
          border-radius: 11.11vw
          font-size: 3.89vw
  
  section
    +margin(1.25vw 16.67vw 0)

    +tablet
      +margin(3.13vw 3.13vw 0)
      +border(0.13vw solid $border-color-base, top bottom)

    +mobile
      +margin(6.67vw 5.56vw 0)
      +border(0.28vw solid $border-color-base, top bottom)

    .title-wrap
      +padding(1.25vw 1.04vw)
      +border(0.05vw solid $border-color-table, bottom)

      +tablet
        +padding(3.13vw 2.6vw)
        +border(0.13vw solid $border-color-table, bottom)

      +mobile
        +padding(4.44vw)
        +border(0.28vw solid $border-color-table, bottom)

      p
        +margin(top 0.63vw)
        color: $gray-700

        +tablet
          +margin(top 1.56vw)

        +mobile
          +margin(top 1.11vw)

    .content-wrap
      +padding(2.08vw 1.04vw)
      +border(0.05vw solid $border-color-table, bottom)

      +tablet
        +padding(5.21vw 2.6vw)
        +border(0.13vw solid $border-color-table, bottom)

      +mobile
        +padding(11.11vw 5.56vw)
        +border(0.28vw solid $border-color-table, bottom)

    .file-wrap
      +flex-box(null, center, 0.63vw)
      +padding(1.25vw 1.04vw)
      font-weight: bold
      font-size: 0.83vw
      line-height: 1.63

      +tablet
        +padding(3.13vw 2.6vw)
        gap: 1.56vw
        font-weight: bold
        font-size: 2.08vw

      +mobile
        +padding(4.44vw)
        gap: 3.33vw
        font-weight: bold
        font-size: 3.89vw

      a
        +flex-box(null, center, 0.21vw)
        font-weight: normal
        text-decoration: underline

        +tablet
          gap: 0.52vw

        +mobile
          gap: 1.11vw

        img
          +size(1.25vw, 1.25vw)

          +tablet
            +size(3.13vw, 3.13vw)

          +mobile
            +size(5.56vw, 5.56vw)

  .answer-section
    +margin(top 0)
    +border(0)
    background-color: $background-color-article

  .button-section
    +margin(1.25vw 0 6.25vw)
    +padding(0 16.67vw)
    +flex-box(flex-end)

    +tablet
      +margin(3.13vw 0 15.63vw)
      +padding(0 3.13vw)

    +mobile
      +margin(4.44vw auto 22.22vw)
      +padding(0 5.56vw)

    a
      +btn(null, null, 0.31vw)
      +size(3.13vw, 1.88vw)
      background-color: #e6e9f1
      line-height: 1.88vw
      font-size: 0.83vw
      font-weight: normal

      +tablet
        +size(7.81vw, 4.69vw)
        border-radius: 0.78vw
        line-height: 4.69vw
        font-size: 2.08vw

      +mobile
        +size(15.83vw, 9.17vw)
        border-radius: 1.67vw
        font-size: 3.89vw
        line-height: 9.17vw
